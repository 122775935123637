/* eslint-disable */

import Vue from 'vue'
import VueRouter from 'vue-router'
import {Trans} from '../plugins/Translation'
import auth from '../middleware/auth'
import guest from '../middleware/guest'
import admin from '../middleware/admin'
import notAdmin from '../middleware/notAdmin'

Vue.use(VueRouter)

// pages
const Home = () => import('../views/Home.vue')
const Schedule = () => import('../views/Schedule.vue')
const Watch = () => import('../views/Watch.vue')
const Bracket = () => import('../views/Bracket.vue')
const News = () => import('../views/News.vue')
const Tournament = () => import('../views/Tournament.vue')
const TournamentDetail = () => import('../views/TournamentDetail.vue')
const Regulation = () => import('../views/Regulation.vue')
const HowToJoin = () => import('../views/HowToJoin.vue')
const Teams = () => import('../views/Teams.vue')
const TeamsDetail = () => import('../views/TeamsDetail.vue')
const TeamProfile = () => import('../views/TeamProfile.vue')
const TeamPlayer = () => import('../views/TeamPlayer.vue')
const Register = () => import('../views/Register.vue')
const Login = () => import('../views/Login.vue')
const ForgotPassword = () => import('../views/ForgotPassword.vue')

// manage
const ManageLayout = () => import('../views/manage/Layout.vue')
const ManageLogin = () => import('../views/manage/Login.vue')
const ManageTournament = () => import('../views/manage/Tournament.vue')
const ManageParticipant = () => import('../views/manage/Participant.vue')
const ManageSchedule = () => import('../views/manage/Schedule.vue')
const ManageWatch = () => import('../views/manage/Watch.vue')
const ManageBracket = () => import('../views/manage/Bracket.vue')
const ManageTeam = () => import('../views/manage/Team.vue')


const routes = [
    {
        path: '/:locale',
        component: {
            render (c) {return c('router-view')}
        },
        beforeEnter: Trans.routeMiddleware,
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
            },
            {
                path: 'schedule',
                name: 'Schedule',
                component: Schedule,
            },
            {
                path: 'watch',
                name: 'Watch',
                component: Watch,
            },
            {
                path: 'bracket',
                name: 'Bracket',
                component: Bracket,
            },
            {
                path: 'news',
                name: 'News',
                component: News,
            },
            {
                path: 'tournament',
                name: 'Tournament',
                component: Tournament,
            },
            {
                path: 'tournament/:id',
                name: 'TournamentDetail',
                component: TournamentDetail,
                props: true,
            },
            {
                path: 'regulation',
                name: 'Regulation',
                redirect: () => {
                    return 'regulation/rule-book'
                },
            },
            {
                path: 'regulation/rule-book',
                name: 'RegRuleBook',
                component: Regulation,
            },
            {
                path: 'regulation/global-policy',
                name: 'RegGLobalPolicy',
                component: Regulation,
            },
            {
                path: 'regulation/tournament-eligibility-en',
                name: 'RegTournamentEligibilityEn',
                component: Regulation,
            },
            {
                path: 'regulation/tournament-eligibility-id',
                name: 'RegTournamentEligibilityId',
                component: Regulation,
            },
            {
                path: 'regulation/rule-challengers-en',
                name: 'RegRuleChallengersEn',
                component: Regulation,
            },
            {
                path: 'regulation/rule-challengers-id',
                name: 'RegRuleChallengersId',
                component: Regulation,
            },
            {
                path: 'regulation/vct-concurrent',
                name: 'RegVctConcurrent',
                component: Regulation,
            },
            {
                path: 'regulation/bug-exploit',
                name: 'RegBugExploit',
                component: Regulation,
            },
            {
                path: 'teams',
                name: 'Teams',
                component: Teams,
            },
            {
                path: 'teams/:id',
                name: 'TeamsDetail',
                component: TeamsDetail,
                props: true,
            },
            {
                path: 'how-to-join',
                name: 'Howto',
                component: HowToJoin,
            },
            {
                path: 'my-team',
                name: 'MyTeam',
                component: {
                    render (c) {return c('router-view')}
                },
                beforeEnter: auth,
                redirect: () => {
                    return 'my-team/profile'
                },
                children: [
                    {
                        path: 'profile',
                        name: 'TeamProfile',
                        component: TeamProfile
                    },
                    {
                        path: 'player',
                        name: 'TeamPlayer',
                        component: TeamPlayer
                    },
                ]
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
                beforeEnter: guest,
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                beforeEnter: guest,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                beforeEnter: guest,
            }
        ]
    },
    {
        path: '/:locale/manage',
        name: 'ManageLogin',
        component: ManageLogin,
        beforeEnter: notAdmin,
    },
    {
        path: '/:locale/manage/tournament',
        name: 'ManageTournament',
        component: ManageTournament,
        beforeEnter: admin,
    },
    {
        path: '/:locale/manage/team',
        name: 'ManageTeam',
        component: ManageTeam,
        beforeEnter: admin,
    },
    {
        path: '/:locale/manage/tournament',
        component: ManageLayout,
        beforeEnter: Trans.routeMiddleware,
        props: true,
        children: [
            {
                path: 'participant/:id',
                name: 'ManageParticipant',
                component: ManageParticipant,
                props: true,
                beforeEnter: admin,
            },
            {
                path: 'bracket/:id',
                name: 'ManageBracket',
                component: ManageBracket,
                props: true,
                beforeEnter: admin,
            },
            {
                path: 'schedule',
                name: 'ManageSchedule',
                component: ManageSchedule,
                beforeEnter: admin,
            },
            {
                path: 'watch',
                name: 'ManageWatch',
                component: ManageWatch,
                beforeEnter: admin,
            },
            // {
            //     path: 'team',
            //     name: 'ManageTeam',
            //     component: ManageTeam,
            //     beforeEnter: admin,
            // },
        ]
    },
    {
        path: "*",
        redirect() {
            return Trans.userLocale.locale
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: "_active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    base: process.env.BASE_URL,
    routes
})

router.afterEach(() => {
    window.scrollTo({
        top: 0,
        left: 0
    })
})
  
export default router